const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  purge: ['./pages/**/*.{js,jsx,ts,tsx}', './components/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      fontFamily: {
        sans: ["'Fira Sans Condensed'", ...defaultTheme.fontFamily.sans],
        logo: ['Pacifico', 'cursive']
      },
      spacing: {
        '1px': '1px',
        72: '18rem',
        84: '21rem',
        96: '24rem',
        108: '27rem',
        120: '30rem',
        132: '33rem',
        sidebar: '25rem'
      },
      inset: {
        '1/2': '50%',
        '4': defaultTheme.spacing[4],
        '8': defaultTheme.spacing[8],
        '16': defaultTheme.spacing[16]
      },
      height: {
        'full-4': `calc(100% - ${defaultTheme.spacing[4]})`,
        'full-16': `calc(100% - ${defaultTheme.spacing[16]})`
      },
      width: {
        'full-4': `calc(100% - ${defaultTheme.spacing[4]})`,
        'full-6': `calc(100% - ${defaultTheme.spacing[6]})`
      },
      zIndex: {
        100: 100,
        max: 2147483647
      }
    }
  },
  variants: {},
  plugins: [require('@tailwindcss/custom-forms')]
};
