/**
 * Export full Tailwind theme for JS consumption.
 *
 * https://tailwindcss.com/docs/configuration/#referencing-in-javascript
 */
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from 'tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

export default fullConfig.theme;
